import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'

Rails.start()
Turbolinks.start()
ActiveStorage.start()
import 'stylesheets/cms'
import '@fortawesome/fontawesome-free/js/all'
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
import 'trix'
import '@rails/actiontext'
